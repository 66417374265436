import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { GameCard } from '../components/games/GameCard';
import { GameFilters } from '../components/games/GameFilters';
import { Toast } from '../components/common/Toast';
import { gamesApi } from '../services/api';
import { ParticleField } from '../components/common/ParticleField';

type FilterType = "new" | "top_day" | "top_week" | "top_month" | "following" | "liked";

export const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const [games, setGames] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState<FilterType>("new");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchGames = useCallback(async () => {
    try {
      setLoading(true);
      const { games: newGames, pagination } = await gamesApi.getPublic(filter, page);
      
      if (page === 1) {
        setGames(newGames);
      } else {
        setGames(prev => [...prev, ...newGames]);
      }
      
      setHasMore(pagination.has_more);
    } catch (error) {
      Toast.error('Failed to load games');
    } finally {
      setLoading(false);
    }
  }, [filter, page]);

  useEffect(() => {
    setPage(1);
  }, [filter]);

  useEffect(() => {
    fetchGames();
  }, [fetchGames]);

  const handleLoadMore = () => {
    if (!loading && hasMore) {
      setPage(prev => prev + 1);
    }
  };

  return (
    <div className="min-h-screen bg-black text-white relative">
      <ParticleField />
      <div className="relative z-10">
        <div className="max-w-7xl mx-auto px-4 py-8">
          <GameFilters
            selectedFilter={filter}
            onFilterChange={(newFilter) => setFilter(newFilter as FilterType)}
          />
          
          <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {games.map((game) => (
              <GameCard 
                key={game.kv_key} 
                game={game} 
                onClick={() => navigate(`/g/${game.kv_key}`)}
              />
            ))}
          </div>

          {loading && (
            <div className="fixed inset-0 flex items-center justify-center bg-black/50 z-50">
              <div className="flex flex-col items-center">
                <div className="w-8 h-8 border-4 border-white/20 border-t-white rounded-full animate-spin mb-2" />
                <span className="text-white/60">Loading</span>
              </div>
            </div>
          )}

          {!loading && hasMore && (
            <div className="mt-8 text-center">
              <button
                onClick={handleLoadMore}
                className="px-6 py-2 bg-white/10 text-white rounded-lg hover:bg-white/20 transition-colors"
              >
                Load More
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}; 