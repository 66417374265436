import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GameCard } from '../components/games/GameCard';
import { useNavigate } from 'react-router-dom';
import { Toast } from '../components/common/Toast';
import { Game } from '../types/game';
import { userApi, gamesApi, socialApi } from '../services/api';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

interface UserProfile {
  username: string;
  bio: string | null;
  website: string | null;
  tiktok_profile: string | null;
  x_profile: string | null;
  twitch_profile: string | null;
  games_count: number;
  followers_count: number;
  following_count: number;
  is_following: boolean;
}

interface GameWithAuthor extends Game {
  author: {
    id: number;
    username: string;
  };
  comments_count: number;
}

const SocialButton: React.FC<{
  href: string | null;
  icon: React.ReactNode;
  label: string;
}> = ({ href, icon, label }) => (
  <a
    href={href || '#'}
    target="_blank"
    rel="noopener noreferrer"
    className={`flex items-center space-x-1 px-3 py-1 rounded-lg text-sm transition-colors ${
      href 
        ? 'bg-white/10 hover:bg-white/20 text-white' 
        : 'bg-white/5 text-white/40 cursor-not-allowed'
    }`}
    onClick={e => !href && e.preventDefault()}
  >
    {icon}
    <span>{label}</span>
  </a>
);

export const UserPage: React.FC = () => {
  const { username } = useParams<{ username: string }>();
  const navigate = useNavigate();
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [games, setGames] = useState<GameWithAuthor[]>([]);
  const [loading, setLoading] = useState(true);
  const { isAuthenticated, user } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!username) return;

      try {
        setLoading(true);
        const [profileData, gamesData] = await Promise.all([
          userApi.getProfile(username),
          gamesApi.getUserGames(username, 'public', 1)
        ]);

        // Check if user data exists
        if (!profileData.user) {
          throw new Error('User not found');
        }

        setProfile({
          ...profileData.user,
          games_count: gamesData.pagination.total,
        });

        setGames(gamesData.games.map(game => ({
          ...game,
          author: {
            id: 0,
            username: username
          },
          comments_count: 0,
        })));
      } catch (error) {
        Toast.error('Failed to load user data');
        navigate('/');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [username, navigate]);

  const handleFollowClick = async () => {
    if (!isAuthenticated) {
      Toast.error('Please sign in to follow users');
      return;
    }

    try {
      if (profile?.is_following) {
        await socialApi.unfollow(username!);
        Toast.success(`Unfollowed ${username}`);
      } else {
        await socialApi.follow(username!);
        Toast.success(`Following ${username}`);
      }
      
      // Update profile with new follow state
      setProfile(prev => prev ? {
        ...prev,
        is_following: !prev.is_following,
        followers_count: prev.followers_count + (prev.is_following ? -1 : 1)
      } : null);
    } catch (error) {
      Toast.error('Failed to update follow status');
    }
  };

  const showFollowButton = isAuthenticated && user?.username !== username;

  if (loading || !profile) {
    return (
      <div className="flex items-center justify-center min-h-[calc(100vh-64px)]">
        <div className="w-8 h-8 border-4 border-white/20 border-t-white rounded-full animate-spin" />
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      {/* User Profile Header */}
      <div className="bg-white/5 rounded-lg p-8 mb-8">
        <div className="flex flex-col md:flex-row md:items-start gap-8">
          {/* Avatar */}
          <div className="flex-shrink-0">
            <div className="w-32 h-32 rounded-full bg-emerald-600 flex items-center justify-center text-4xl font-bold">
              {profile.username.charAt(0).toUpperCase()}
            </div>
          </div>

          {/* User Info */}
          <div className="flex-grow">
            <div className="flex flex-col md:flex-row md:items-center gap-4 mb-6">
              <div className="flex items-center gap-4">
                <h1 className="text-3xl font-bold">{profile.username}</h1>
                {showFollowButton && (
                  <button
                    onClick={handleFollowClick}
                    className={`px-4 py-1 rounded-lg text-sm font-medium transition-colors ${
                      profile.is_following
                        ? 'bg-white text-black hover:bg-white/90'
                        : 'bg-white/10 text-white hover:bg-white/20'
                    }`}
                  >
                    {profile.is_following ? 'Following' : 'Follow'}
                  </button>
                )}
              </div>
              <div className="flex flex-wrap gap-4">
                <span className="px-4 py-2 bg-white/10 rounded-lg">
                  {profile.games_count} games
                </span>
                <span className="px-4 py-2 bg-white/10 rounded-lg">
                  {profile.followers_count} followers
                </span>
                <span className="px-4 py-2 bg-white/10 rounded-lg">
                  {profile.following_count} following
                </span>
              </div>
            </div>

            {/* Social Links */}
            <div className="flex flex-wrap gap-2 mb-4">
              <SocialButton
                href={profile.website}
                icon={
                  <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                  </svg>
                }
                label="Website"
              />
              <SocialButton
                href={profile.tiktok_profile}
                icon={
                  <svg className="w-4 h-4" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M19.59 6.69a4.83 4.83 0 01-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 01-5.2 1.74 2.89 2.89 0 012.31-4.64 2.93 2.93 0 01.88.13V9.4a6.84 6.84 0 00-1-.05A6.33 6.33 0 005 20.1a6.34 6.34 0 0010.86-4.43v-7a8.16 8.16 0 004.77 1.52v-3.4a4.85 4.85 0 01-1-.1z"/>
                  </svg>
                }
                label="TikTok"
              />
              <SocialButton
                href={profile.x_profile}
                icon={
                  <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
                  </svg>
                }
                label="X"
              />
              <SocialButton
                href={profile.twitch_profile}
                icon={
                  <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M11.571 4.714h1.715v5.143H11.57zm4.715 0H18v5.143h-1.714zM6 0L1.714 4.286v15.428h5.143V24l4.286-4.286h3.428L22.286 12V0zm14.571 11.143l-3.428 3.428h-3.429l-3 3v-3H6.857V1.714h13.714Z"/>
                  </svg>
                }
                label="Twitch"
              />
            </div>

            {/* Bio */}
            <p className="text-lg text-white/80">
              {profile.bio || "No bio available"}
            </p>
          </div>
        </div>
      </div>

      {/* Games Grid */}
      <h2 className="text-2xl font-bold mb-6">Published Games</h2>
      {games.length === 0 ? (
        <p className="text-white/60">No games published yet</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {games.map((game) => (
            <GameCard
              key={game.kv_key}
              game={game}
              onClick={() => navigate(`/g/${game.kv_key}`)}
            />
          ))}
        </div>
      )}
    </div>
  );
}; 