import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Toast } from '../common/Toast';
import { gamesApi } from '../../services/api';

interface GenerationModalProps {
  isOpen: boolean;
  onClose: () => void;
  parentGame?: {
    kv_key: string;
  };
}

interface VersionPrompt {
  kv_key: string;
  username: string;
  prompt: string;
  version_number: number;
  created_at: string;
}

const getTimeAgo = (date: string) => {
  const seconds = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000);

  let interval = seconds / 31536000; // years
  if (interval > 1) return Math.floor(interval) + ' years ago';
  
  interval = seconds / 2592000; // months
  if (interval > 1) return Math.floor(interval) + ' months ago';
  
  interval = seconds / 86400; // days
  if (interval > 1) return Math.floor(interval) + ' days ago';
  
  interval = seconds / 3600; // hours
  if (interval > 1) return Math.floor(interval) + ' hours ago';
  
  interval = seconds / 60; // minutes
  if (interval > 1) return Math.floor(interval) + ' minutes ago';
  
  return Math.floor(seconds) + ' seconds ago';
};

export const GenerationModal: React.FC<GenerationModalProps> = ({
  isOpen,
  onClose,
  parentGame,
}) => {
  const navigate = useNavigate();
  const [prompt, setPrompt] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [generationError, setGenerationError] = useState<string | null>(null);
  const [versionHistory, setVersionHistory] = useState<VersionPrompt[]>([]);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);

  useEffect(() => {
    const fetchVersionHistory = async () => {
      if (!parentGame) return;
      
      try {
        setIsLoadingHistory(true);
        const { versions } = await gamesApi.getGamePrompts(parentGame.kv_key);
        setVersionHistory(versions);
      } catch (error) {
        console.error('Failed to load version history:', error);
      } finally {
        setIsLoadingHistory(false);
      }
    };

    if (isOpen && parentGame) {
      fetchVersionHistory();
    }
  }, [isOpen, parentGame]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!prompt.trim()) return;

    try {
      setIsGenerating(true);
      setGenerationError(null);

      const response = await gamesApi.generate({
        prompt,
        ...(parentGame ? { parent_kv_key: parentGame.kv_key } : {}),
      });

      if (response.status === 2) {
        // Generation successful
        Toast.success('Game generated successfully!');
        onClose();
        navigate(`/v/${response.kv_key}`);
      } else {
        // Generation failed
        setGenerationError(response.error || 'Failed to generate game');
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to start generation';
      setGenerationError(errorMessage);
    } finally {
      setIsGenerating(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-black/90 p-8 rounded-lg border border-white/10 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <h2 className="text-2xl font-bold mb-6">
          {parentGame ? 'Improve Game' : 'Create New Game'}
        </h2>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <textarea
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              rows={4}
              className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-transparent"
              placeholder={parentGame 
                ? "Describe how you want to improve this game..."
                : "Describe the game you want to create..."
              }
              disabled={isGenerating}
            />
          </div>

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-white/10 text-white rounded-lg hover:bg-white/20 transition-colors disabled:opacity-50"
              disabled={isGenerating}
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isGenerating || !prompt.trim()}
              className="px-4 py-2 bg-white text-black rounded-lg hover:bg-white/90 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
            >
              {isGenerating ? (
                <>
                  <div className="w-4 h-4 border-2 border-black/20 border-t-black rounded-full animate-spin" />
                  <span>Generating...</span>
                </>
              ) : (
                <span>{parentGame ? 'Improve' : 'Create'}</span>
              )}
            </button>
          </div>

          {generationError && (
            <div className="p-4 bg-red-500/10 border border-red-500/20 rounded-lg text-red-400 text-sm">
              {generationError}
            </div>
          )}

          {parentGame && (
            <div className="mt-8 space-y-4">
              <h3 className="text-lg font-medium text-white/80">Version History</h3>
              {isLoadingHistory ? (
                <div className="text-center py-4">
                  <div className="w-6 h-6 border-2 border-white/20 border-t-white rounded-full animate-spin mx-auto" />
                </div>
              ) : (
                <div className="space-y-3">
                  {versionHistory.map((version) => (
                    <div 
                      key={version.kv_key}
                      className="p-4 bg-white/5 rounded-lg space-y-3"
                    >
                      <div className="space-y-2">
                        <Link
                          to={`/v/${version.kv_key}`}
                          onClick={(e) => e.stopPropagation()}
                          className="block text-2xl font-bold text-emerald-400 hover:text-emerald-300 transition-colors"
                        >
                          Version {version.version_number}
                        </Link>
                        <div className="flex items-center justify-between text-sm">
                          <Link
                            to={`/user/${version.username}`}
                            onClick={(e) => e.stopPropagation()}
                            className="text-emerald-400 hover:text-emerald-300 transition-colors"
                          >
                            {version.username}
                          </Link>
                          <span className="text-white/40">
                            {getTimeAgo(version.created_at)}
                          </span>
                        </div>
                      </div>
                      <p className="text-white/80 border-t border-white/10 pt-3">
                        {version.prompt}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </form>
      </div>
    </div>
  );
}; 