import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { setUser } from '../store/slices/authSlice';
import { Toast } from '../components/common/Toast';
import { userApi } from '../services/api';

export const ProfilePage: React.FC = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    username: user?.username || '',
    bio: user?.bio || '',
    website: user?.website || '',
    tiktok_profile: user?.tiktok_profile || '',
    x_profile: user?.x_profile || '',
    twitch_profile: user?.twitch_profile || ''
  });

  if (!user) {
    return (
      <div className="min-h-screen bg-black text-white flex items-center justify-center">
        <p>Please connect your wallet to view your profile.</p>
      </div>
    );
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const { user: updatedUser } = await userApi.updateProfile(formData);
      dispatch(setUser(updatedUser));
      setIsEditing(false);
      Toast.success('Profile updated successfully');
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Failed to update profile';
      Toast.error(message);
    }
  };

  return (
    <div className="min-h-screen bg-black text-white">
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="bg-white/5 rounded-lg p-6 border border-white/10">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold">Profile Settings</h1>
            {!isEditing && (
              <button
                onClick={() => setIsEditing(true)}
                className="px-4 py-2 bg-white/10 text-white rounded-lg hover:bg-white/20 transition-colors"
              >
                Edit Profile
              </button>
            )}
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-white/60 mb-2">
                Wallet Address
              </label>
              <input
                type="text"
                value={user.user_address}
                disabled
                className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white/60"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-white/60 mb-2">
                Username
              </label>
              <input
                type="text"
                value={formData.username}
                onChange={(e) => setFormData({ ...formData, username: e.target.value })}
                disabled={!isEditing}
                className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent disabled:text-white/60"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-white/60 mb-2">
                Bio
              </label>
              <textarea
                value={formData.bio}
                onChange={(e) => setFormData({ ...formData, bio: e.target.value })}
                disabled={!isEditing}
                rows={4}
                className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent disabled:text-white/60"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-white/60 mb-2">
                Website
              </label>
              <input
                type="url"
                value={formData.website}
                onChange={(e) => setFormData({ ...formData, website: e.target.value })}
                disabled={!isEditing}
                className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent disabled:text-white/60"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-white/60 mb-2">
                TikTok Profile
              </label>
              <input
                type="text"
                value={formData.tiktok_profile}
                onChange={(e) => setFormData({ ...formData, tiktok_profile: e.target.value })}
                disabled={!isEditing}
                className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent disabled:text-white/60"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-white/60 mb-2">
                X Profile
              </label>
              <input
                type="text"
                value={formData.x_profile}
                onChange={(e) => setFormData({ ...formData, x_profile: e.target.value })}
                disabled={!isEditing}
                className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent disabled:text-white/60"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-white/60 mb-2">
                Twitch Profile
              </label>
              <input
                type="text"
                value={formData.twitch_profile}
                onChange={(e) => setFormData({ ...formData, twitch_profile: e.target.value })}
                disabled={!isEditing}
                className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent disabled:text-white/60"
              />
            </div>

            {isEditing && (
              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={() => {
                    setIsEditing(false);
                    setFormData({
                      username: user.username || '',
                      bio: user.bio || '',
                      website: user.website || '',
                      tiktok_profile: user.tiktok_profile || '',
                      x_profile: user.x_profile || '',
                      twitch_profile: user.twitch_profile || ''
                    });
                  }}
                  className="px-4 py-2 bg-white/10 text-white rounded-lg hover:bg-white/20 transition-colors"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-white text-black rounded-lg hover:bg-white/90 transition-colors"
                >
                  Save Changes
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}; 