import React from 'react';
import { useParams } from 'react-router-dom';
import { GameNavbar } from '../components/games/GameNavbar';
import { API_URL } from '../services/api';

export const GamePage: React.FC = () => {
  const { kv_key } = useParams<{ kv_key: string }>();

  if (!kv_key) {
    return (
      <div className="fixed inset-0 bg-black text-white flex items-center justify-center">
        Game not found
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black">
      <div className="h-16">
        <GameNavbar kv_key={kv_key} />
      </div>
      <div className="h-[calc(100vh-64px)]">
        <iframe
          src={`${API_URL}/g/${kv_key}.html`}
          className="w-full h-full"
          title="Game"
        />
      </div>
    </div>
  );
}; 