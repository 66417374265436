import React, { useRef, useEffect } from 'react';

interface Particle {
  x: number;
  y: number;
  vx: number;
  vy: number;
  size: number;
  targetX: number;
  targetY: number;
}

export const ParticleField: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const particlesRef = useRef<Particle[]>([]);
  const animationFrameRef = useRef<number>();
  const isTransitioningRef = useRef(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const setSize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    setSize();
    window.addEventListener('resize', setSize);

    if (particlesRef.current.length === 0) {
      const numParticles = 60;
      for (let i = 0; i < numParticles; i++) {
        particlesRef.current.push({
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          vx: 0,
          vy: 0,
          size: Math.random() * 1.2 + 0.3,
          targetX: Math.random() * canvas.width,
          targetY: Math.random() * canvas.height,
        });
      }
    }

    const animate = () => {
      ctx.fillStyle = 'rgba(0, 0, 0, 0.1)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      
      particlesRef.current.forEach((particle, i) => {
        const dx = particle.targetX - particle.x;
        const dy = particle.targetY - particle.y;
        
        const distanceMultiplier = 40;
        const transitionForce = 0.005;
        const maxSpeed = 1.5;
        const friction = 0.97;
        
        if (Math.random() < 0.02) {
          particle.targetX = particle.x + (Math.random() - 0.5) * distanceMultiplier;
          particle.targetY = particle.y + (Math.random() - 0.5) * distanceMultiplier;
        }
        
        particle.vx += dx * transitionForce;
        particle.vy += dy * transitionForce;
        
        particle.vx = Math.max(Math.min(particle.vx, maxSpeed), -maxSpeed);
        particle.vy = Math.max(Math.min(particle.vy, maxSpeed), -maxSpeed);

        particle.vx *= friction;
        particle.vy *= friction;

        const perlinScale = 0.1;
        particle.x += Math.sin(Date.now() * 0.001 + i) * perlinScale;
        particle.y += Math.cos(Date.now() * 0.001 + i) * perlinScale;

        particle.x += particle.vx;
        particle.y += particle.vy;

        if (particle.x < -50) particle.x = canvas.width + 50;
        if (particle.x > canvas.width + 50) particle.x = -50;
        if (particle.y < -50) particle.y = canvas.height + 50;
        if (particle.y > canvas.height + 50) particle.y = -50;

        ctx.shadowBlur = 12;
        ctx.shadowColor = i % 5 === 0 ? 'rgba(16, 185, 129, 0.6)' : 'rgba(255, 255, 255, 0.6)';
        ctx.beginPath();
        ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2);
        ctx.fillStyle = i % 5 === 0 ? 'rgba(16, 185, 129, 0.6)' : 'rgba(255, 255, 255, 0.6)';
        ctx.fill();
        ctx.shadowBlur = 0;
      });

      animationFrameRef.current = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      window.removeEventListener('resize', setSize);
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, []);

  return (
    <canvas 
      ref={canvasRef} 
      className="fixed inset-0 z-0 pointer-events-none" 
    />
  );
}; 