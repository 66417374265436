import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { UsernameModal } from './UsernameModal';

interface RequireUsernameProps {
  children: React.ReactNode;
}

export const RequireUsername: React.FC<RequireUsernameProps> = ({ children }) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const [modalClosed, setModalClosed] = useState(false);

  // If user has a username or modal was manually closed, render children
  if (user?.username || modalClosed) {
    return <>{children}</>;
  }

  // If user is not logged in, render children
  if (!user) {
    return <>{children}</>;
  }

  // If user is logged in but has no username, show modal
  return (
    <>
      {user?.user_address && (
        <UsernameModal
          isOpen={true}
          address={user.user_address}
          onComplete={() => setModalClosed(true)}
        />
      )}
    </>
  );
}; 