import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { GenerationModal } from './GenerationModal';
import { ConnectWallet } from '../auth/ConnectWallet';
import { Toast } from '../../components/common/Toast';
import { gamesApi } from '../../services/api';

interface GameNavbarProps {
  kv_key: string;
}

interface GameDetails {
  id: number;
  kv_key: string;
  name: string;
  author_id: number;
  author_username: string;
  is_public: boolean;
  version_number: number;
  variant_index: number;
  created_at: string;
  likes_count: number;
  views_count: number;
  is_author: boolean;
  is_liked: boolean;
}

export const GameNavbar: React.FC<GameNavbarProps> = ({ kv_key }) => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const [showGenerationModal, setShowGenerationModal] = useState(false);
  const [showConnectWallet, setShowConnectWallet] = useState(false);
  const [game, setGame] = useState<GameDetails | null>(null);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isLiking, setIsLiking] = useState(false);

  useEffect(() => {
    const fetchGame = async () => {
      try {
        const { game: gameDetails } = await gamesApi.getGame(kv_key);
        setGame(gameDetails);
      } catch (error) {
        console.error('Failed to load game info:', error);
      }
    };

    fetchGame();
  }, [kv_key]);

  const handlePublishToggle = async () => {
    if (!game || !game.is_author || isPublishing) return;

    try {
      setIsPublishing(true);
      const wasPublic = game.is_public;
      const { success } = await gamesApi.publish(kv_key);
      
      if (success) {
        setGame(prev => prev ? { ...prev, is_public: !wasPublic } : null);
        Toast.success(wasPublic ? 'Game hidden' : 'Game published');
      }
    } catch (error) {
      Toast.error('Failed to update game status');
    } finally {
      setIsPublishing(false);
    }
  };

  const handleImprove = () => {
    if (isAuthenticated) {
      setShowGenerationModal(true);
    } else {
      setShowConnectWallet(true);
    }
  };

  const handleShare = () => {
    const url = window.location.href;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile && navigator.share) {
      navigator.share({
        title: game?.name || 'Check out this game',
        text: 'Check out this game on degs',
        url
      });
    } else {
      navigator.clipboard.writeText(url);
      Toast.success('Link copied to clipboard');
    }
  };

  const handleLike = async () => {
    if (!isAuthenticated) {
      setShowConnectWallet(true);
      return;
    }

    if (!game || isLiking) return;

    try {
      setIsLiking(true);
      const { success, liked } = await gamesApi.like(kv_key);
      
      if (success) {
        setGame(prev => prev ? {
          ...prev,
          is_liked: liked,
          likes_count: prev.likes_count + (liked ? 1 : -1)
        } : null);
      }
    } catch (error) {
      Toast.error('Failed to update like status');
    } finally {
      setIsLiking(false);
    }
  };

  return (
    <>
      <nav className="fixed w-full z-50 top-0 left-0 px-6 py-4 bg-black/80 backdrop-blur-sm border-b border-white/10">
        <div className="max-w-7xl mx-auto flex items-center">
          {/* Left side */}
          <div className="flex-1">
            {game?.is_author && (
              <button
                onClick={handlePublishToggle}
                disabled={isPublishing}
                className="px-3 py-1.5 text-sm bg-white/10 text-white font-medium rounded-lg hover:bg-white/20 transition-colors border border-white/20 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isPublishing ? 'updating...' : game.is_public ? 'hide' : 'publish'}
              </button>
            )}
          </div>

          {/* Center */}
          <div className="flex-1 flex justify-center">
            {showConnectWallet ? (
              <ConnectWallet onNewUser={() => setShowConnectWallet(false)} />
            ) : (
              <button
                onClick={handleImprove}
                className="px-4 py-2 bg-white text-black font-medium rounded-lg hover:bg-white/90 transition-colors"
              >
                improve
              </button>
            )}
          </div>

          {/* Right side */}
          <div className="flex-1 flex items-center justify-end space-x-4">
            <Link to="/" className="text-white/60 hover:text-white transition-colors">
              <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
              </svg>
            </Link>
            <button
              onClick={handleLike}
              disabled={isLiking}
              className={`transition-colors ${
                game?.is_liked
                  ? 'text-red-500 hover:text-red-600'
                  : 'text-white/60 hover:text-white'
              } ${isLiking ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              <svg 
                className="w-6 h-6" 
                fill={game?.is_liked ? "currentColor" : "none"} 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" 
                />
              </svg>
            </button>
            <button
              className="text-white/60 hover:text-white transition-colors"
              onClick={handleShare}
            >
              <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
              </svg>
            </button>
          </div>
        </div>
      </nav>

      {showGenerationModal && (
        <GenerationModal
          isOpen={showGenerationModal}
          onClose={() => setShowGenerationModal(false)}
          parentGame={{ kv_key }}
        />
      )}
    </>
  );
}; 