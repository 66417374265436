import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { RootState } from '../store';
import { GameCard } from '../components/games/GameCard';
import { Toast } from '../components/common/Toast';
import { gamesApi } from '../services/api';
import { Game } from '../types/game';

export const LibraryPage: React.FC = () => {
  const navigate = useNavigate();
  const { user, isAuthenticated } = useSelector((state: RootState) => state.auth);
  const [publicGames, setPublicGames] = useState<Game[]>([]);
  const [privateGames, setPrivateGames] = useState<Game[]>([]);
  const [loading, setLoading] = useState(true);
  const [publicPage, setPublicPage] = useState(1);
  const [privatePage, setPrivatePage] = useState(1);
  const [hasMorePublic, setHasMorePublic] = useState(true);
  const [hasMorePrivate, setHasMorePrivate] = useState(true);

  const fetchGames = useCallback(async () => {
    if (!user?.username) return;

    try {
      setLoading(true);

      // Fetch public games
      const publicResponse = await gamesApi.getUserGames(user.username, 'public', publicPage);
      const publicGamesWithAuthor = publicResponse.games.map(game => ({
        ...game,
        author: {
          id: user.id,
          username: user.username!
        },
        comments_count: 0 // This will be updated when comments are implemented
      }));

      if (publicPage === 1) {
        setPublicGames(publicGamesWithAuthor);
      } else {
        setPublicGames(prev => [...prev, ...publicGamesWithAuthor]);
      }
      setHasMorePublic(publicResponse.pagination.has_more);

      // Fetch all games (including private)
      const privateResponse = await gamesApi.getUserGames(user.username, 'all', privatePage);
      const onlyPrivateGames = privateResponse.games
        .filter(game => !game.is_public)
        .map(game => ({
          ...game,
          author: {
            id: user.id,
            username: user.username!
          },
          comments_count: 0 // This will be updated when comments are implemented
        }));
      
      if (privatePage === 1) {
        setPrivateGames(onlyPrivateGames);
      } else {
        setPrivateGames(prev => [...prev, ...onlyPrivateGames]);
      }
      setHasMorePrivate(privateResponse.pagination.has_more);

    } catch (error) {
      Toast.error('Failed to load games');
    } finally {
      setLoading(false);
    }
  }, [user?.username, user?.id, publicPage, privatePage]);

  useEffect(() => {
    fetchGames();
  }, [fetchGames]);

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  const handleLoadMorePublic = () => {
    if (!loading && hasMorePublic) {
      setPublicPage(prev => prev + 1);
    }
  };

  const handleLoadMorePrivate = () => {
    if (!loading && hasMorePrivate) {
      setPrivatePage(prev => prev + 1);
    }
  };

  return (
    <div className="min-h-screen bg-black text-white">
      <div className="max-w-7xl mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-8">My Library</h1>

        {/* Public Games Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-6">Public Games</h2>
          {publicGames.length === 0 && !loading ? (
            <p className="text-white/60">No public games yet</p>
          ) : (
            <>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                {publicGames.map((game) => (
                  <GameCard 
                    key={game.kv_key} 
                    game={game}
                    onClick={() => navigate(`/versions/${game.kv_key}`)}
                  />
                ))}
              </div>
              {hasMorePublic && !loading && (
                <div className="mt-8 text-center">
                  <button
                    onClick={handleLoadMorePublic}
                    className="px-6 py-2 bg-white/10 text-white rounded-lg hover:bg-white/20 transition-colors"
                  >
                    Load More
                  </button>
                </div>
              )}
            </>
          )}
        </section>

        {/* Private Games Section */}
        <section>
          <h2 className="text-2xl font-semibold mb-6">Private Games</h2>
          {privateGames.length === 0 && !loading ? (
            <p className="text-white/60">No private games yet</p>
          ) : (
            <>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                {privateGames.map((game) => (
                  <GameCard 
                    key={game.kv_key} 
                    game={game}
                    onClick={() => navigate(`/versions/${game.kv_key}`)}
                  />
                ))}
              </div>
              {hasMorePrivate && !loading && (
                <div className="mt-8 text-center">
                  <button
                    onClick={handleLoadMorePrivate}
                    className="px-6 py-2 bg-white/10 text-white rounded-lg hover:bg-white/20 transition-colors"
                  >
                    Load More
                  </button>
                </div>
              )}
            </>
          )}
        </section>

        {loading && (
          <div className="mt-8 text-center text-white/60">
            <div className="w-8 h-8 border-4 border-white/20 border-t-white rounded-full animate-spin mx-auto" />
          </div>
        )}
      </div>
    </div>
  );
}; 