import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { gamesApi } from '../services/api';
import { Toast } from '../components/common/Toast';

interface GameVariant {
  kv_key: string;
  name: string;
  author_username: string;
  variant_index: number;
  created_at: string;
  is_public: boolean;
}

interface VersionsResponse {
  versions: {
    [key: string]: GameVariant[];
  };
}

export const GameVersionsPage: React.FC = () => {
  const { kv_key } = useParams<{ kv_key: string }>();
  const [versions, setVersions] = useState<VersionsResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVersions = async () => {
      if (!kv_key) return;

      try {
        setLoading(true);
        const response = await gamesApi.getVersions(kv_key);
        console.log('API Response:', response);
        setVersions(response);
      } catch (error) {
        console.error('Error loading versions:', error);
        Toast.error('Failed to load game versions');
      } finally {
        setLoading(false);
      }
    };

    fetchVersions();
  }, [kv_key]);

  if (loading) {
    return (
      <div className="min-h-screen bg-black text-white flex items-center justify-center">
        <div className="w-8 h-8 border-4 border-white/20 border-t-white rounded-full animate-spin" />
      </div>
    );
  }

  if (!versions || Object.keys(versions.versions).length === 0) {
    return (
      <div className="min-h-screen bg-black text-white flex items-center justify-center">
        <p className="text-white/60">No versions found</p>
      </div>
    );
  }

  // Converti l'oggetto versions in un array ordinato per numero di versione
  const versionNumbers = Object.keys(versions.versions)
    .map(Number)
    .sort((a, b) => b - a); // Ordine decrescente

  return (
    <div className="min-h-screen bg-black text-white">
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-3xl font-bold">Game Versions</h1>
          <Link
            to="/library"
            className="px-4 py-2 bg-white/10 text-white rounded-lg hover:bg-white/20 transition-colors"
          >
            Back to Library
          </Link>
        </div>

        <div className="space-y-8">
          {versionNumbers.map((versionNumber) => (
            <div key={versionNumber} className="bg-white/5 rounded-lg p-6">
              <h2 className="text-2xl font-semibold mb-4">Version {versionNumber}</h2>
              <div className="space-y-4">
                {versions.versions[versionNumber].map((variant) => (
                  <div
                    key={variant.kv_key}
                    className="bg-white/5 rounded-lg p-4 hover:bg-white/10 transition-colors"
                  >
                    <div className="flex items-center justify-between mb-2">
                      <div>
                        <h3 className="text-lg font-medium mb-1">{variant.name}</h3>
                        <p className="text-white/60 text-sm">
                          by {variant.author_username} • Variant {variant.variant_index}
                        </p>
                      </div>
                      <Link
                        to={`/v/${variant.kv_key}`}
                        className="px-4 py-2 bg-white text-black rounded-lg hover:bg-white/90 transition-colors"
                      >
                        Play
                      </Link>
                    </div>
                    <div className="flex items-center justify-between text-sm">
                      <span className="text-white/40">
                        Created on {new Date(variant.created_at).toLocaleDateString()}
                      </span>
                      {variant.is_public && (
                        <span className="inline-block px-2 py-1 text-xs bg-white/10 rounded-full">
                          Public
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}; 