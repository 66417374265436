import React from 'react';

interface EnergyDisplayProps {
  energies: number;
  stakedAmount: number;
}

export const EnergyDisplay: React.FC<EnergyDisplayProps> = ({ energies }) => {
  return (
    <div className="flex items-center">
      <div className="px-3 py-1 bg-white/10 rounded-lg text-sm flex items-center space-x-1">
        <svg className="w-4 h-4 text-yellow-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
        </svg>
        <span className="font-medium">{energies}</span>
      </div>
    </div>
  );
}; 