import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, setLoading, setError } from '../../store/slices/authSlice';
import { authService } from '../../services/auth';
import { RootState } from '../../store';
import { Toast } from '../common/Toast';
import { authApi } from '../../services/api';

interface ConnectWalletProps {
  onNewUser?: (address: string) => void;
}

export const ConnectWallet: React.FC<ConnectWalletProps> = ({ onNewUser }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state: RootState) => state.auth);

  const handleConnect = async () => {
    try {
      dispatch(setLoading(true));
      dispatch(setError(null));

      // Connect wallet
      const loadingToast = Toast.loading('Connecting wallet...');
      const address = await authService.connectWallet();
      
      // Get challenge
      Toast.loading('Getting challenge...', { id: loadingToast });
      const { challenge } = await authApi.getChallenge();
      
      // Sign challenge
      Toast.loading('Please sign the message...', { id: loadingToast });
      const { signature } = await authService.signMessage(challenge);
      
      // Verify signature
      Toast.loading('Verifying signature...', { id: loadingToast });
      const { session_token, user: userData } = await authApi.verifySignature(address, signature, challenge);
      
      // IMPORTANTE: Salva il token PRIMA di qualsiasi altra operazione
      localStorage.setItem('session_token', session_token);
      
      if (!userData.username) {
        if (onNewUser) {
          onNewUser(address);
        }
        Toast.success('Wallet connected! Please set your username.', { id: loadingToast });
      } else {
        dispatch(setUser(userData));
        Toast.success('Connected successfully!', { id: loadingToast });
      }
    } catch (error) {
      console.error('Failed to connect wallet:', error);
      const message = error instanceof Error ? error.message : 'Failed to connect wallet';
      dispatch(setError(message));
      Toast.error(message);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <button
      onClick={handleConnect}
      disabled={isLoading}
      className="px-4 py-2 bg-white text-black font-medium rounded-lg hover:bg-white/90 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
    >
      {isLoading ? 'Connecting...' : 'Connect Wallet'}
    </button>
  );
}; 