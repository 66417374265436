import { toast, ToastOptions } from 'react-hot-toast';

// Stile base per tutti i toast
const baseStyle: ToastOptions = {
  style: {
    background: 'rgba(0, 0, 0, 0.8)',
    color: '#fff',
    backdropFilter: 'blur(8px)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    borderRadius: '8px',
  },
  duration: 4000,
};

type ToastFunction = (message: string, options?: ToastOptions) => string;

export const Toast = {
  success: ((message: string, options?: ToastOptions) =>
    toast.success(message, {
      ...baseStyle,
      ...options,
      icon: '✓',
    })) as ToastFunction,

  error: ((message: string, options?: ToastOptions) =>
    toast.error(message, {
      ...baseStyle,
      ...options,
      icon: '✕',
    })) as ToastFunction,

  loading: ((message: string, options?: ToastOptions) =>
    toast.loading(message, {
      ...baseStyle,
      ...options,
    })) as ToastFunction,

  info: ((message: string, options?: ToastOptions) =>
    toast(message, {
      ...baseStyle,
      ...options,
      icon: 'ℹ',
    })) as ToastFunction,
}; 