import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { ConnectWallet } from './auth/ConnectWallet';
import { EnergyDisplay } from './energy/EnergyDisplay';
import { UsernameModal } from './auth/UsernameModal';
import { GenerationModal } from './games/GenerationModal';
import { logout } from '../store/slices/authSlice';

export const Navbar: React.FC = () => {
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector((state: RootState) => state.auth);
  const [showUsernameModal, setShowUsernameModal] = useState(false);
  const [showGenerationModal, setShowGenerationModal] = useState(false);
  const [pendingAddress, setPendingAddress] = useState<string | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleNewUser = (address: string) => {
    setPendingAddress(address);
    setShowUsernameModal(true);
  };

  const handleRegistrationComplete = () => {
    setShowUsernameModal(false);
    setPendingAddress(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('session_token');
    dispatch(logout());
    setIsMenuOpen(false);
  };

  const handleCreateGame = () => {
    if (isAuthenticated) {
      setShowGenerationModal(true);
    }
  };

  return (
    <>
      <nav className="fixed w-full z-50 top-0 left-0 px-6 py-4 bg-black/80 backdrop-blur-sm border-b border-white/10">
        <div className="max-w-7xl mx-auto flex items-center justify-between">
          <Link to="/" className="text-2xl font-bold">
            degs
          </Link>

          <div className="flex items-center space-x-6">
            {isAuthenticated ? (
              <>
                <EnergyDisplay
                  energies={user?.daily_energies_remaining || 0}
                  stakedAmount={user?.staked_amount || 0}
                />
                
                <div className="relative" ref={menuRef}>
                  <button
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                    className="flex items-center space-x-2 text-white hover:text-white/90 focus:outline-none"
                  >
                    <span className="hidden md:block">
                      {user?.username || user?.user_address.slice(0, 6) + '...' + user?.user_address.slice(-4)}
                    </span>
                    <div className="h-8 w-8 rounded-full bg-white/10 flex items-center justify-center">
                      <span className="text-sm text-white">
                        {user?.username?.[0] || user?.user_address.slice(0, 2)}
                      </span>
                    </div>
                  </button>

                  {isMenuOpen && user && (
                    <div className="absolute right-0 mt-2 w-48 bg-black/90 backdrop-blur-sm rounded-lg border border-white/10 overflow-hidden">
                      <div className="px-4 py-3 border-b border-white/10">
                        <p className="text-sm font-medium truncate">{user.username}</p>
                        <p className="text-xs text-white/40 truncate">{user.user_address}</p>
                        <div className="mt-2 space-y-1">
                          <p className="text-xs text-white/60">
                            Energy: <span className="text-white">{user.daily_energies_remaining}</span>
                          </p>
                          <p className="text-xs text-white/60">
                            Staked: <span className="text-white">{user.staked_amount} DEGS</span>
                          </p>
                        </div>
                      </div>
                      <Link
                        to="/profile"
                        className="block px-4 py-2 text-sm text-white/60 hover:text-white hover:bg-white/10 transition-colors"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        Profile
                      </Link>
                      <Link
                        to="/library"
                        className="block px-4 py-2 text-sm text-white/60 hover:text-white hover:bg-white/10 transition-colors"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        My Library
                      </Link>
                      <button
                        onClick={handleLogout}
                        className="w-full px-4 py-2 text-left text-sm text-white/60 hover:text-white hover:bg-white/10 transition-colors"
                      >
                        Logout
                      </button>
                    </div>
                  )}
                </div>

                <button
                  onClick={handleCreateGame}
                  className="px-4 py-2 bg-white text-black font-medium rounded-lg hover:bg-white/90 transition-colors"
                >
                  create
                </button>
              </>
            ) : (
              <ConnectWallet onNewUser={handleNewUser} />
            )}
          </div>
        </div>
      </nav>

      {showUsernameModal && pendingAddress && (
        <UsernameModal
          isOpen={showUsernameModal}
          address={pendingAddress}
          onComplete={handleRegistrationComplete}
        />
      )}

      {showGenerationModal && (
        <GenerationModal
          isOpen={showGenerationModal}
          onClose={() => setShowGenerationModal(false)}
        />
      )}
    </>
  );
}; 