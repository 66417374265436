import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Toast } from '../components/common/Toast';
import { gamesApi } from '../services/api';

export const WaitingPage: React.FC = () => {
  const { kv_key } = useParams<{ kv_key: string }>();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!kv_key) return;

    const checkStatus = async () => {
      try {
        const { status, error: statusError } = await gamesApi.getStatus(kv_key);

        if (status === 2) {
          // Game is ready
          navigate(`/games/${kv_key}`);
          return;
        }

        if (status < 0) {
          // Game generation failed
          setError(statusError || 'Generation failed');
          Toast.error(statusError || 'Generation failed');
          return;
        }

        // Status is still 0 (pending), check again in 2 seconds
        setTimeout(checkStatus, 2000);
      } catch (error) {
        const message = error instanceof Error ? error.message : 'Failed to check game status';
        setError(message);
        Toast.error(message);
      }
    };

    // Start checking status
    checkStatus();

    // Cleanup on unmount
    return () => {
      // This will be called if the component unmounts while we're still checking
      setError(null);
    };
  }, [kv_key, navigate]);

  return (
    <div className="min-h-screen bg-black text-white flex flex-col items-center justify-center p-4">
      <div className="text-center space-y-4">
        {error ? (
          <>
            <h1 className="text-2xl font-bold text-red-500">Generation Failed</h1>
            <p className="text-white/60">{error}</p>
            <button
              onClick={() => navigate('/')}
              className="px-4 py-2 bg-white text-black font-medium rounded-lg hover:bg-white/90 transition-colors"
            >
              Back to Home
            </button>
          </>
        ) : (
          <>
            <div className="w-16 h-16 border-4 border-white/20 border-t-white rounded-full animate-spin mb-4" />
            <h1 className="text-2xl font-bold">Generating Your Game</h1>
            <p className="text-white/60">This might take a few minutes...</p>
          </>
        )}
      </div>
    </div>
  );
}; 