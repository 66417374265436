import { ethers } from 'ethers';

export const authService = {
  connectWallet: async () => {
    if (!window.ethereum) {
      throw new Error('MetaMask is not installed');
    }

    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const accounts = await provider.send('eth_requestAccounts', []);
      return accounts[0];
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message);
      }
      throw new Error('Failed to connect wallet');
    }
  },

  signMessage: async (challenge: string) => {
    if (!window.ethereum) {
      throw new Error('MetaMask is not installed');
    }

    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      
      // Costruisci il messaggio esattamente come il backend
      const message = `Sign this message to prove ownership: ${challenge}`;
      console.log('Message to sign:', message);
      
      const signature = await signer.signMessage(message);
      console.log('Signature:', signature);
      
      return { signature };
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message);
      }
      throw new Error('Failed to sign message');
    }
  },
}; 